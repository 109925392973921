import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";
import Headline from "../components/typography/headline";
import Paragraph from "../components/typography/paragraph";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Contact" />
      <SimplePage
        title="Contact"
        description="Get in touch with us."
        fluid={data.contact.childImageSharp.fluid}
      >
        <Headline>Want to get in touch?</Headline>
        <Paragraph>
          Email contact@imagineart.ai, and we’ll do our best to respond within
          24 hours.
        </Paragraph>
      </SimplePage>
    </Layout>
  );
};

export default ContactPage;
